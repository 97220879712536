<template>
  <v-chip
    :class="`v-chip-light-bg ${color}--text`"
    :small="small"
  >
    <v-icon
      class="mr-1"
      :color="color"
      :small="small"
    >
      {{ icon }}
    </v-icon>
    <slot>
      {{ value }}
    </slot>
  </v-chip>
</template>

<script>
export default {
  props: {
    value: {
      type: String,
      default: '',
    },
    small: {
      type: Boolean,
      default: false,
    },
    icon: {
      type: String,
      default: null,
    },
    color: {
      type: String,
      default: 'primary',
    },
  },
}
</script>
