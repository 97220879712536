/* eslint-disable no-unused-vars */
/* eslint-disable no-param-reassign */
import dayjs from 'dayjs'
import { v4 as uuidv4 } from 'uuid'
import Vue from 'vue'
import { getField, updateField } from 'vuex-map-fields'

const initialState = {
  error: false,
  syncing: false,
  syncingPos: 0,
  syncingCnt: 1,
  syncingTxt: 'Communicating...',
  isSynced: false,
  baseDataLoaded: false,
  items: [],
  baseData: [],
  apiDetails: {},
}

function isObject(candidate) {
  return (typeof candidate === 'object' || typeof candidate === 'function') && (candidate !== null)
}

function removeJoinData(object) {
  const filtered = { ...object }
  Object.keys(filtered).forEach(key => {
    if (key === '_joinData') {
      delete filtered[key]
    } else if (isObject(filtered[key])) {
      filtered[key] = removeJoinData(filtered[key])
    }
  })

  return filtered
}

export default {
  namespaced: true,
  state: {
    ...initialState,
  },
  getters: {
    getField,
    getLength: state => state.items.length,
    getLastId: state => (state.items.length > 0 ? state.items[state.items.length - 1].id : false),
    syncing: state => state.syncing,
    syncingPos: state => state.syncingPos,
    syncingCnt: state => state.syncingCnt,
    syncingTxt: state => state.syncingTxt,
    isSynced: state => state.isSynced,
    encountersToSync: state => state.items.filter(encounter => (!encounter.is_synced)),
    baseDataLoaded: state => state.baseDataLoaded,
    abdominalExams: state => state.baseData.abdominalExams,
    antibioticRoutes: state => state.baseData.antibioticRoutes,
    billingStatuses: state => state.baseData.billingStatuses,
    bodyAreas: state => state.baseData.bodyAreas,
    chronicWounds: state => state.baseData.chronicWounds,
    continenceOptions: state => state.baseData.continence,
    credentialPayers: state => state.baseData.credentialPayers,
    debridementNotPerformedReasons: state => state.baseData.debridementNotPerformedReasons,
    dermEtiologies: state => state.baseData.dermEtiologies,
    dressings: state => state.baseData.dressings,
    durations: state => state.baseData.durations,
    exudateAmounts: state => state.baseData.exudateAmounts,
    exudateTypes: state => state.baseData.exudateTypes,
    followUpActivities: state => state.baseData.followUpActivities,
    followUpIntervals: state => state.baseData.followUpIntervals,
    footwearNotableIssues: state => state.baseData.footwearNotableIssues,
    gTubeTypes: state => state.baseData.gTubeTypes,
    genders: state => state.baseData.genders,
    generalAppearances: state => state.baseData.generalAppearances,
    goals: state => state.baseData.goals,
    healthChanges: state => state.baseData.healthChanges,
    infectionSigns: state => state.baseData.infectionSigns,
    labOptions: state => state.baseData.labs,
    lesionConditions: state => state.baseData.lesionConditions,
    mattresses: state => state.baseData.mattresses,
    mattressTypes: state => state.baseData.mattressTypes,
    medicalHistories: state => state.baseData.medicalHistories,
    medications: state => state.baseData.medications,
    mentalStatuses: state => state.baseData.mentalStatuses,
    mobilityOptions: state => state.baseData.mobility,
    months: state => state.baseData.months,
    neuropathyTypes: state => state.baseData.neuropathyTypes,
    neuropathySeverities: state => state.baseData.neuropathySeverities,
    odors: state => state.baseData.odors,
    offloadings: state => state.baseData.offloadings,
    periTubeFindings: state => state.baseData.periTubeFindings,
    periTubeTracts: state => state.baseData.periTubeTracts,
    periwounds: state => state.baseData.periwounds,
    preoperativeIndications: state => state.baseData.preoperativeIndications,
    opNoteBloodLosses: state => state.baseData.opNoteBloodLosses,
    opNoteCleansings: state => state.baseData.opNoteCleansings,
    opNoteHemostases: state => state.baseData.opNoteHemostases,
    opNoteIncisionTypes: state => state.baseData.opNoteIncisionTypes,
    opNoteInstruments: state => state.baseData.opNoteInstruments,
    opNotePainManagements: state => state.baseData.opNotePainManagements,
    opNotePositionings: state => state.baseData.opNotePositionings,
    opNoteVisualizedBones: state => state.baseData.opNoteVisualizedBones,
    opNoteVisualizedMuscles: state => state.baseData.opNoteVisualizedMuscles,
    prognoses: state => state.baseData.prognoses,
    progresses: state => state.baseData.progresses,
    reasonForOngoingDebridement: state => state.baseData.reasonForOngoingDebridement,
    replacementReasons: state => state.baseData.replacementReasons,
    riskFactors: state => state.baseData.riskFactors,
    socialHistoryOptions: state => state.baseData.socialHistories,
    temperatureUnits: state => state.baseData.temperatureUnits,
    testResults: state => state.baseData.testResults,
    testTypes: state => state.baseData.testTypes,
    topicalAgents: state => state.baseData.topicalAgents,
    verificationOfPlacements: state => state.baseData.verificationOfPlacements,
    visitLocations: state => state.baseData.visitLocations,
    visitTypes: state => state.baseData.visitTypes,
    woundEdges: state => state.baseData.woundEdges,
    woundEtiologies: state => state.baseData.woundEtiologies,
    woundStages: state => state.baseData.woundStages,
    appearances: state => state.baseData.appearances,
    orientations: state => state.baseData.orientations,
    memories: state => state.baseData.memories,
    intellects: state => state.baseData.intellects,
    concentrations: state => state.baseData.concentrations,
    insights: state => state.baseData.insights,
    judgements: state => state.baseData.judgements,
    behaviors: state => state.baseData.behaviors,
    speeches: state => state.baseData.speeches,
    thoughtFlows: state => state.baseData.thoughtFlows,
    moods: state => state.baseData.moods,
    affects: state => state.baseData.affects,
    strengths: state => state.baseData.strengths,
    limitations: state => state.baseData.limitations,
    impairedFunctions: state => state.baseData.impairedFunctions,
    logStatus: state => state.baseData.logStatus,
    logType: state => state.baseData.logType,
    getById(state) {
      return (id, idField = 'id') => state.items.find(item => item[idField] === id) || {}
    },
    getIndexById: state => id => state.items.findIndex(x => x.id === id),
    isDuplicateEncounter: state => (encounter, authUserId) => {
      const index = state.items
        .findIndex(x => (
          x.created_by_user_id === authUserId
          && x.created_by_user_id === encounter.created_by_user_id
          && x.place_of_service_id === encounter.place_of_service_id
          && x.visit_date === encounter.visit_date
          && x.patient_id === encounter.patient_id
          && x.practice_type_id === encounter.practice_type_id
          && x.deleted !== true
        ))
      if (index === -1) return false

      return state.items[index].id
    },
    isPriorEncounter: state => encounter => {
      const index = state.items
        .findIndex(x => (
          x.created_by_user_id === encounter.created_by_user_id
          && x.place_of_service_id === encounter.place_of_service_id
          && x.visit_date >= encounter.visit_date
          && x.patient_id === encounter.patient_id
          && x.practice_type_id === encounter.practice_type_id
          && x.id !== encounter.id
          && x.deleted !== true
        ))
      if (index === -1) return false

      return !!state.items[index]
    },

    /*
      Used only in encounters/Create.vue to decide if it's a new or follow-up visit.
      So there's no current encounter ID (currentId) to exclude.
      Probably shouldn't use this getter for other uses, use previousEncounters() instead.
    */
    previousEncounterId: state => (patientId, practiceTypeId, newVisitDate) => {
      const index = state.items
        .sort((a, b) => (a.visit_date <= b.visit_date && 1) || -1)
        .findIndex(x => (
          x.patient_id === patientId
          && x.practice_type_id === practiceTypeId
          && x.deleted !== true

          // Removed by SWC-850: && x.is_signed
        ))

      // If previous encounter is in the future or > 36 months, ignore
      if (index === -1
        || dayjs().diff(state.items[index].visit_date, 'month') > 36
      ) return false

      return state.items[index].id
    },

    /*
      Used only in encounters/Create.vue to decide if it's a new or follow-up visit.
      So there's no current encounter ID (currentId) to exclude.
      Probably shouldn't use this getter for other uses, use previousEncounters() instead.
    */
    previousEncounter: state => (patientId, practiceTypeId, newVisitDate) => {
      const index = state.items
        .sort((a, b) => (a.visit_date < b.visit_date && 1) || -1)
        .findIndex(x => (
          x.patient_id === patientId
          && x.practice_type_id === practiceTypeId
          && x.deleted !== true

          // Removed by SWC-850: && x.is_signed
        ))

      // If previous encounter is in the future or > 36 months, ignore
      if (index === -1
        || dayjs(newVisitDate).diff(state.items[index].visit_date, 'day') < 1
        || dayjs().diff(state.items[index].visit_date, 'month') > 36
      ) return false

      return state.items[index]
    },

    // Probably should use this getter to find previous encounters
    previousEncounters: state => (patientId, practiceTypeId, currentId, days) => state.items
      .filter(x => (
        x.patient_id === patientId
        && x.practice_type_id === practiceTypeId
        && x.id !== currentId
        && x.is_signed
        && x.deleted !== true
        && dayjs().diff(x.visit_date, 'days') <= days
      )).sort((a, b) => (a.visit_date < b.visit_date && 1) || -1),

    // Getter for retrieving any signed or unsigned encounter, possible temp duplicate
    previousSignedUnsignedEncounters: state => (patientId, practiceTypeId, currentId, date, days) => state.items
      .filter(note => (
        note.patient_id === patientId
      && note.practice_type_id === practiceTypeId
      && note.id !== currentId
      && note.deleted !== true
      && note.visit_date < date
      && dayjs().diff(note.visit_date, 'days') <= days
      )).sort((a, b) => (a.visit_date < b.visit_date && 1) || -1),

    unsyncedEncounters: state => (patientId, practiceTypeId, currentId, days) => state.items
      .filter(note => (
        note.patient_id === patientId
      && note.practice_type_id === practiceTypeId
      && note.id !== currentId
      && note.deleted !== true
      && note.is_synced !== true
      && dayjs().diff(note.visit_date, 'days') <= days
      )).sort((a, b) => (a.visit_date < b.visit_date && 1) || -1),

    getCalloutByPatient: state => patientId => state.items.filter(encounter => (
      encounter.patient_id === patientId && encounter.billing_callout !== undefined
    )),
  },
  mutations: {
    DEBUG__deleteEncounters: (state, userID) => {
      const localEncounters = state.items.filter(encounter => (
        !encounter.is_synced
            && encounter.created_by_user_id === userID
      ))

      const newStateItems = state.items
      for (let i = localEncounters.length - 1; i >= 0; i--) {
        state.items.splice(newStateItems.findIndex(index => index.id === localEncounters[i].id), 1)
      }
      state.isSynced = false
    },

    updateField,
    updateCommLog: (state, { encounterIds, commLogData }) => {
      // Update the communication_log property for each encounter with the specified IDs
      encounterIds.forEach(encounterId => {
        const index = state.items.findIndex(x => x.id === encounterId)

        if (index !== -1) {
          state.items[index] = {
            ...state.items[index],

            // Update the encounter's communication_log_id
            communication_log_id: commLogData.id,

            // Set the encounter's related communication_log
            communication_log: { ...commLogData },

            // Mark as not synced since we still have to push the updated communication_log_id to the server on the user's next sync.
            is_synced: false,
          }
        }
      })

      // Set synced status to false
      state.isSynced = false
    },
    addEncounter: (state, value) => {
      const newValue = {
        ...value,
        id: uuidv4(),
        is_synced: false,
      }
      state.items.push(newValue)

      // Set synced status to false
      state.isSynced = false
    },
    addEncounters: (state, value) => {
      value.forEach(note => {
        state.items.push(note)
      })

      // Set synced status to false
      state.isSynced = false
    },
    updateEncounter: (state, data) => {
      const patientsToUpdate = []
      let encounterArr = []
      if (Array.isArray(data)) {
        encounterArr = data
      } else {
        encounterArr.push(data)
      }
      encounterArr.forEach(e => {
        // Find the encounter index
        const index = state.items.findIndex(x => x.id === e.encounter.id || x.id === e.id)

        // Update encounter values
        if (index !== -1) state.items[index] = { ...e.encounter }

        /// If patient data is included, update the patient (patient includes wound treatments)
        if (e.patient && e.patient?.id) patientsToUpdate.push(e.patient)
      })
      if (patientsToUpdate.length !== 0) Vue.store.commit('patients/updatePatient', patientsToUpdate)

      // Set synced status to false
      state.isSynced = false
    },
    updateEncounterAttachments: (state, { id = false, attachments = [] }) => {
      // Find the encounter index
      const index = state.items.findIndex(x => x.id === id)

      // Update encounter attachments
      if (index !== -1) {
        state.items[index].encounter_attachments = [...attachments]
        state.items[index].is_synced = false
      }

      // Set synced status to false
      state.isSynced = false
    },
    deleteEncounter: (state, value) => {
      state.items = state.items.filter(x => x.id !== value.id)

      // Set synced status to false
      state.isSynced = false
    },
    encounterChanged: (state, value) => {
      // Find the encounter index
      const index = state.items.findIndex(x => x.id === value.id)
      if (index !== -1) {
        state.items[index].is_validated = false
        state.items[index].is_synced = false
      }

      // Set synced status to false
      state.isSynced = false
    },
    synced: (state, value) => {
      state.isSynced = value
    },
    RESET_STATE: state => {
      Object.keys(initialState).forEach(key => {
        state[key] = initialState[key]
      })
    },
    SET_ENCOUNTERS(state, data) {
      Vue.set(state, 'items', data)
    },
    SET_BASE_DATA: (state, data) => {
      state.baseData = data

      // Topical Agents is a bit unique, so we'll reformat the data
      const topicalAgents = []
      data.topicalAgents.forEach((value, index) => {
        topicalAgents[index] = value.title
      })
      state.baseData.topicalAgents = topicalAgents

      // Visualized Muscles is a bit unique, so we'll reformat the data
      data.opNoteVisualizedMuscles.forEach((value, index) => {
        if (value.title.length > 10 && value.title.substring(0, 10) === '{{HEADER}}') {
          data.opNoteVisualizedMuscles[index] = {
            id: value.id,
            header: value.title.substring(10),
          }
        }
      })
      state.baseData.opNoteVisualizedMuscles = data.opNoteVisualizedMuscles

      state.baseDataLoaded = true
    },
    SET_SYNCING: (state, value) => {
      state.syncing = value
    },
    SET_SYNCING_POS: (state, value) => {
      state.syncingPos = value
    },
    SET_SYNCING_CNT: (state, value) => {
      state.syncingCnt = value
    },
    SET_SYNCING_TXT: (state, value) => {
      state.syncingTxt = value
    },
    SET_SYNCED: (state, value) => {
      state.isSynced = value
    },
    SET_API_DETAILS: (state, data) => {
      state.apiDetails = data
      console.log(`Total encounters synced: ${data.items}`)
      console.log(`Query: ${data.query_time}s, Optimize: ${data.optimize_time}s`)
    },
  },
  actions: {
    syncingModal({ commit, state }, value) {
      if (value) {
        commit('SET_SYNCING', true)
      } else {
        const timeout = Math.max(0, (1 - state.syncingPos / state.syncingCnt) * 400) + 200
        commit('SET_SYNCING_TXT', 'Communicating...')
        commit('SET_SYNCING_POS', 1)
        commit('SET_SYNCING_CNT', 1)
        setTimeout(() => {
          commit('SET_SYNCING', false)
          commit('SET_SYNCING_POS', 0)
        }, timeout)
      }
    },
    syncingProgress({ commit }, value) {
      commit('SET_SYNCING_POS', value.pos)
      commit('SET_SYNCING_CNT', value.cnt)
    },
    setSynced({ commit }, value) {
      commit('SET_SYNCED', value)
    },
    syncEncounter({ commit }, data) {
      const encountersToSync = []
      let encounterArr = []
      if (Array.isArray(data)) {
        encounterArr = data
      } else {
        encounterArr.push(data)
      }
      encounterArr.forEach(encounter => {
        encountersToSync.push({
        // Remove all '_joinData' objects
          ...removeJoinData(encounter),

          // Filter out the already synced attachments
          encounter_attachments: encounter?.encounter_attachments
            ? encounter.encounter_attachments?.filter(x => x.updated === true)
            : [],
        })
      })

      return Vue.axios.post('encounters.json', encountersToSync)
        .then(response => response.data.responseData)
        .catch(e => Vue.prototype.$custom.processCommError(e, 'Upload Encounter'))
    },
    sendCommLog({ commit }, commLog) {
      return Vue.axios.post('communication-logs.json', commLog)
        .then(response => {
          if (response.data.status === 'Success') {
            // Destructure the 'encounters' property from 'response.data.data'
            // and gather the remaining properties into the 'commLogData' object.
            const { encounters, ...commLogData } = response.data.data

            // Extract the 'id' property from each encounter in the 'encounters' array.
            const encounterIds = response.data.data.encounters.map(encounter => encounter.id)

            // Commit to state
            commit('updateCommLog', { encounterIds, commLogData })

            return true
          }
          throw response.data.message
        })
        .catch(error => {
          Vue.prototype.$custom.processCommError(error, 'send communication log')
          throw error
        })
    },
    getEstimatedPayment({ commit }, id) {
      return Vue.axios.get(`/encounters/${id}.json`)
        .then(response => (response.data.status === 'Success' ? response.data.encounter.estimated_payment : false))
        .catch(e => Vue.prototype.$custom.processCommError(e, 'get estimated payment'))
    },
    loadEncounters({ commit }) {
      console.log('Getting encounters...')
      commit('SET_SYNCED', false)
      let items = 0
      let queryTime = 0
      let optimizeTime = 0
      let encounterData = []
      commit('encounters/SET_SYNCING_TXT', 'Fetching encounter data', { root: true })
      const fetchPage = page => Vue.axios.get(`encounters.json?page=${page}`)
        .then(response => {
          if (response.data.status === 'Success') {
            const { totalPages } = response.data

            commit('encounters/SET_SYNCING_TXT', `Fetching encounter data: page ${page + 1} of ${totalPages}`, { root: true })
            console.log(`GET encounters progress: page ${page} of ${totalPages}`)

            encounterData = [...encounterData, ...response.data.encounters]
            items += response.data.details.items
            queryTime += parseFloat(response.data.details.query_time)
            optimizeTime += parseFloat(response.data.details.optimize_time)

            // If there are more pages, fetch the next page recursively
            if (response.data.hasMorePages) {
              // Clear references to release memory.
              response = null

              return fetchPage(page + 1)
            }

            // Make all signed encounters non-reactive
            encounterData.forEach(Vue.prototype.$custom.freezeSignedEncounters)

            // Create a new object with updated values
            const updatedResponseData = {
              ...response.data, // Copy all properties from the original response
              encounters: encounterData,
              details: {
                ...response.data.details, // Copy all properties from the original details
                items,
                query_time: queryTime.toFixed(3),
                optimize_time: optimizeTime.toFixed(3),
              },
            }

            // Clear references to release memory.
            response = null
            encounterData = null
            items = null
            queryTime = null
            optimizeTime = null

            // *** For testing only ***
            // const jsonString = JSON.stringify(updatedResponseData);
            // const objSizeBytes = new Blob([jsonString]).size;
            // const objSizeMB = (objSizeBytes / (1024 * 1024)).toFixed(2);
            // console.log(`Object size: ${objSizeMB } MB`);

            // Commit 'SET_ENCOUNTERS' mutation
            commit('SET_ENCOUNTERS', updatedResponseData.encounters)

            // All pages fetched, commit 'SET_API_DETAILS' mutation
            commit('SET_API_DETAILS', Vue.prototype.$custom.deepFreeze(updatedResponseData.details))
            commit('SET_BASE_DATA', updatedResponseData.baseData)
            commit('SET_SYNCED', true)

            return true
          }

          return response.data.message ? response.data.message : 'Download encounters - Unknown error'
        })
        .catch(e => Vue.prototype.$custom.processCommError(e, 'Download encounters'))

      return fetchPage(1) // Start fetching from page 1
    },
  },
}
