<template>
  <v-snackbar
    v-model="notifyVisible"
    :timeout="notify.timeout"
    :color="notify.color"
    transition="slide-y-reverse-transition"
    min-width="300"
    multi-line
    center
    bottom
  >
    <v-row
      align="center"
      align-content="center"
      justify="center"
    >
      <v-icon
        class="ma-0 pl-4"
        left
      >
        {{ computedIcon }}
      </v-icon>

      <v-col>
        {{ notify.value }}
      </v-col>
      <span
        color="white"
        style="padding: 0 12px; font-weight: 500; text-transform: uppercase"
        @click="notifyVisible = false"
      >
        Close
      </span>
    </v-row>
  </v-snackbar>
</template>

<script>
import { mapFields } from 'vuex-map-fields'
import {
  mdiAlertOctagon, mdiAlert, mdiInformation, mdiThumbUp,
} from '@mdi/js'

export default {
  data() {
    return {
      notifyVisible: false,
      notify: {},
      icons: {
        mdiAlertOctagon, mdiAlert, mdiInformation, mdiThumbUp,
      },
    }
  },
  computed: {
    ...mapFields({
      snackbarState: 'notifications.snackbar',
    }),
    computedIcon() {
      switch (this.notify.color) {
        case 'error': return this.icons.mdiAlertOctagon
        case 'warning': return this.icons.mdiAlert
        case 'success': return this.icons.mdiThumbUp
        default: return this.icons.mdiInformation
      }
    },
  },
  watch: {
    // Handle snackbar state changes from the store
    snackbarState: {
      deep: true,
      handler(notify) {
        this.notify = notify
        this.notifyVisible = true
      },
    },
  },
}
</script>
