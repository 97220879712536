<template>
  <v-select
    ref="thisSelector"
    v-model="selectedValue"
    :label="!labelTop ? label : null"
    :items="items"
    :rules="computedRules"
    :suffix="suffix"
    :clearable="isClearable"
    :clear-icon="icons.mdiClose"
    :disabled="disabled"
    :readonly="readonly"
    :item-value="computedValue"
    :item-text="computedText"
    :prepend-icon="prependIcon"
    :append-icon="appendIcon"
    :multiple="multiple"
    :return-object="returnObject"
    :dense="dense"
    :hint="hint"
    :hide-details="hideDetails"
    :single-line="singleLine || labelTop"
    :class="`${(noteRequiredField && !disabled) ? 'required-field': ''} ${labelTop ? 'select-field-label-top' : ''} ${noOutline ? 'no-outline' : ''}`"
    :menu-props="{ offsetY: true, closeOnClick: true }"
    :attach="!notAttached"
    :outlined="!noOutline"
    @click:prepend="$emit('click:prepend', $event)"
    @click:append="appendIconClicked($event)"
    @click:clear="$emit('click:clear', $event)"
    @change="valuesChanged($event)"
    @click="$emit('click', $event)"
  >
    <template
      v-if="labelTop"
      #prepend
    >
      <strong
        v-if="noteRequiredField && !disabled"
        class="error--text"
      >*</strong>
      {{ label }}
    </template>
    <template
      v-if="noteRequiredField && !labelTop"
      #label
    >
      <strong
        v-if="!disabled"
        class="error--text"
      >*</strong>
      {{ label }}
    </template>
  </v-select>
</template>

<script>
/* eslint-disable no-eval */
/* eslint-disable no-underscore-dangle */
import { mdiMenuDown, mdiClose } from '@mdi/js'

export default {
  props: {
    value: {
      type: [String, Number, Boolean, Array, Object],
      default: null,
    },
    items: {
      type: Array,
      default: null,
    },
    label: {
      type: String,
      default: '',
    },
    required: {
      type: Boolean,
      default: null,
    },
    suffix: {
      type: String,
      default: null,
    },
    clearable: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    itemValue: {
      type: String,
      default: null,
    },
    itemText: {
      type: String,
      default: null,
    },
    rules: {
      type: String,
      default: null,
    },
    prependIcon: {
      type: String,
      default: null,
    },
    appendIcon: {
      type: String,
      default: mdiMenuDown,
    },
    multiple: {
      type: Boolean,
      default: false,
    },
    dense: {
      type: Boolean,
      default: false,
    },
    hideDetails: {
      type: Boolean,
      default: false,
    },
    returnObject: {
      type: Boolean,
      default: false,
    },
    hint: {
      type: String,
      default: null,
    },
    singleLine: {
      type: Boolean,
      default: false,
    },
    labelTop: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    notAttached: {
      type: Boolean,
      default: false,
    },
    noOutline: {
      type: Boolean,
      default: false,
    },
    distinctiveNone: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      selectedValue: this.sanitizedValues(),
      icons: { mdiMenuDown, mdiClose },
    }
  },
  computed: {
    noteRequiredField() {
      return this.required && !this.selectedValue
    },
    isClearable() {
      return this.clearable !== 'false'
    },
    computedRules() {
      let rulesArray = []
      if (this.disabled || this.readonly) return rulesArray
      if (this.rules) {
        rulesArray = rulesArray.concat(eval(`this.$validationRules.${this.rules}`))
      }
      if (this.required) {
        rulesArray = this.multiple
          ? rulesArray.concat(this.$validationRules.requiredCheckboxes)
          : rulesArray.concat(this.$validationRules.required)
      }

      return rulesArray
    },
    computedValue() {
      if (this.itemValue) return this.itemValue
      if (this.items && this.items.length) {
        if (typeof this.items[0].id !== 'undefined') return 'id'
        if (typeof this.items[0].value !== 'undefined') return 'value'
        if (typeof this.items[0].title !== 'undefined') return 'title'
        if (typeof this.items[0].label !== 'undefined') return 'label'
      }

      return 'value'
    },
    computedText() {
      if (this.itemText) return this.itemText
      if (this.items && this.items.length) {
        if (typeof this.items[0].title !== 'undefined') return 'title'
      }

      return 'label'
    },
  },
  watch: {
    value() {
      this.selectedValue = this.sanitizedValues()
    },
  },
  methods: {
    valuesChanged(event) {
      if (this.multiple && this.distinctiveNone && this.selectedValue && this.selectedValue.length > 1) {
        if (this.selectedValue.find(x => x.title === 'None')) {
          this.selectedValue = this.selectedValue.filter(x => x.title !== 'None')
          this.$store.dispatch('notify', { value: '"None" unselected as it must be exclusive.', color: 'warning' })
          this.emitChanges(this.selectedValue)
        } else this.emitChanges(event)
      } else this.emitChanges(event)
    },
    emitChanges(event) {
      this.$emit('change', event)
      this.$emit('input', event)
    },
    sanitizedValues() {
      if (Array.isArray(this.value) && this.value.length) {
        return this.value[0]._joinData
          ? this.$custom.toUniqueArray(this.value.map(item => ({ id: item.id, title: item.title })))
          : this.$custom.toUniqueArray(this.value)
      }

      return this.value
    },
    appendIconClicked(event) {
      this.$emit('click:append', event)

      // This allows the selector control/append icon to open the selector as it should
      const field = this.$refs.thisSelector
      if (!field.isMenuActive) {
        this.$refs.thisSelector.isMenuActive = true
        field.focus()
      }
    },
  },
}
</script>

<style lang="scss">
.v-select, .v-menu__content {
  .v-select-list > .v-subheader {
    display: grid;
    font-size: 1rem;
    height: 2rem;
    text-align: center;
    color: var(--v-secondary-lighten5);
    background-color: var(--v-secondary-lighten1);
  }
}
.select-field-label-top {
  display: block !important;
  .v-input__prepend-outer {
    display: block;
    margin-top: 4px;
    margin-right: 0;
    padding-bottom: 1px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    color: var(--v-secondary-darken2)
  }
}
</style>
